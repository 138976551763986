<template>
  <div class="DjMixStartPc">
    <h1> This is from PC Dj's Starting mix page   </h1>
  </div>
</template>
<script>
  export default{
    name: 'DjMixStartPcPage,'
  };
</script>
